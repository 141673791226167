<template>
	<div class="RpaLog"></div>
</template>

<script>
export default {
	components: {},
	name: "RpaLog",
	data() {
		return {};
	},
};
</script>
<style lang="scss" scoped></style>
